import {IChart} from "@/lib/charts/charts";
import {ITab, TabEmpty} from "@/lib/charts/tab";
import {ITableData} from "@/lib/charts/table";
import {EChartsType, init} from "echarts";
import {req} from "@/lib/utils";
import {IDataQuery} from "@/lib/charts/data";

interface IPieDataItem {
    name: string
    value: number
}

export interface IPieModel {
    series: IDataQuery
    chartData?: ITableData
}

export default class Pie implements IChart {
    readonly span: number;
    tab: ITab;
    readonly tabs: ITab[];
    readonly title: string;


    protected model: IPieModel;
    protected showDetail: (title: string, d: ITableData) => void
    protected spin: (spin: boolean) => void

    protected ct: EChartsType | null = null

    constructor(title: string, span: number, data: IPieModel) {
        this.title = title
        this.span = span
        this.tabs = [new TabEmpty()]
        this.tab = this.tabs[0]
        this.model = data
        this.showDetail = (): void => {
            // empty
        }
        this.spin = (): void => {
            // empty
        }
    }

    init(dom: HTMLElement): void {
        this.ct = init(dom, 'dark')
    }

    setOption(): void {
        if (!this.ct) return
        this.ct.setOption({
            title: {
                text: this.title,
                left: 12,
                top: 12,
                subtext:`累计：${this.sum()}`
            },
            tooltip: {
                trigger: 'item',
            },
            legend: {bottom: 8},
            series: {
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter: '{b}\n{d}%'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 24,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: this.transformData()
            }
        })
    }

    sum():number{
        let n = 0
        this.model.chartData?.columns.forEach((v, i) => {
            n += this.model.chartData?.list[0][i]
        })
        return n
    }

    transformData(): IPieDataItem[] {
        const arr: IPieDataItem[] = []
        this.model.chartData?.columns.forEach((v, i) => {
            arr.push({name: v, value: this.model.chartData?.list[0][i]})
        })
        return arr
    }


    load(): Promise<unknown> {
        return new Promise((resolve, reject) => {
            this.spin(true)
            req({
                url: 'query',
                method: 'POST',
                data: {db: this.model.series.db, sql: this.tab.parseGatherSql(this.model.series.sql)},
                success: (r: ITableData) => {
                    this.model.chartData = r
                    resolve(r)
                    this.setOption()
                },
                fail: err => reject(err),
                complete: () => this.spin(false)
            })
        })
    }

    onDetailShow(fn: (title: string, data: ITableData) => void): void {
        this.showDetail = fn
    }

    onSpin(fn: (spin: boolean) => void): void {
        this.spin = fn
    }

    setTab(tab: ITab): void {
        this.tab = tab
    }

}