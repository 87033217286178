import {ISQLDataItem} from "@/layouts/workspace/dashboard/dashboard";
import dayjs from "dayjs";

export interface ITab {
    readonly title: string
    readonly length: number
    readonly format: string

    category(): string[]

    dataFormat(items: ISQLDataItem[]): number[]

    parseGatherSql(sql: string): string

    parseDetailSql(sql: string, offset: number): string
}

export class TabEmpty implements ITab {
    readonly format: string = '';
    readonly length: number = 0;
    readonly title: string = '';

    category(): string[] {
        return [];
    }

    dataFormat(): number[] {
        return [];
    }

    parseDetailSql(sql: string): string {
        return sql;
    }

    parseGatherSql(sql: string): string {
        return sql;
    }

}

export class TabDate implements ITab {
    readonly format: string = 'M月D日';
    readonly length: number = 29;
    readonly title: string = '按日';

    category(): string[] {
        const arr: string[] = []
        let d = new Date()
        for (let i = 0; i < this.length; i++) {
            arr.unshift(dayjs(d).format(this.format))
            // d = new Date(d.setDate(d.getDate() - 1))
            d = this.nextTimeStep(d)
        }
        return arr;
    }

    protected nextTimeStep(d: Date): Date {
        return new Date(d.setDate(d.getDate() - 1))
    }

    dataFormat(items: ISQLDataItem[]): number[] {
        const data: number[] = []
        let d = new Date()
        for (let i = 0; i < this.length; i++) {
            const k = items.find(x => dayjs(x.at).format(this.format) == dayjs(d).format(this.format))
            items.find(x => {
                return dayjs(x.at).format(this.format) == dayjs(d).format(this.format)
            })
            if (k) data.unshift(k.v)
            else data.unshift(0)

            d = this.nextTimeStep(d)
            // d = new Date(d.setDate(d.getDate() - 1))
        }
        return data;
    }

    parseGatherSql(sql: string): string {
        return sql.replaceAll('_TS_', 'day').replaceAll('_ROUND_', `date(now())-'${this.length} day'::interval`)
    }

    parseDetailSql(sql: string, offset: number): string {
        return sql.replaceAll('_ROUND_', `date_trunc('day',now())-'${this.length - offset - 1} day'::interval and date_trunc('day',now())-'${this.length - offset - 2} day'::interval`)
    }
}

export class TabMonth extends TabDate {
    readonly format: string = 'YY年M月';
    readonly length: number = 12;
    readonly title: string = '按月';

    protected nextTimeStep(d: Date): Date {
        return new Date(d.setMonth(d.getMonth() - 1))
    }

    parseGatherSql(sql: string): string {
        return sql.replaceAll('_TS_', 'mon').replaceAll('_ROUND_', `date(now())-'${this.length} mon'::interval`)
    }

    parseDetailSql(sql: string, offset: number): string {
        return sql.replaceAll('_ROUND_', `date_trunc('mon',now())-'${this.length - offset - 1} mon'::interval and date_trunc('mon',now())-'${this.length - offset - 2} mon'::interval`)
    }
}

export class TabHour extends TabDate {
    readonly format: string = 'D日H时';
    readonly length: number = 24;
    readonly title: string = '按时';

    protected nextTimeStep(d: Date): Date {
        return new Date(d.setHours(d.getHours() - 1))
    }

    parseGatherSql(sql: string): string {
        return sql.replaceAll('_TS_', 'hour').replaceAll('_ROUND_', `date_trunc('hour',now())-'${this.length} hour'::interval`)
    }

    parseDetailSql(sql: string, offset: number): string {
        return sql.replaceAll('_ROUND_', `date_trunc('hour',now())-'${this.length - offset - 1} hour'::interval and date_trunc('hour',now())-'${this.length - offset - 2} hour'::interval`)
    }
}