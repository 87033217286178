import {MixBar} from "@/lib/charts/bar";
import {TabDate, TabMonth} from "@/lib/charts/tab";
import {IChart} from "@/lib/charts/charts";
import Pie from "@/lib/charts/pie";
import Sankey, {ISankeyDataItem, ISankeyDataLinkItem} from "@/lib/charts/sankey";
import {JsonObject} from "@/lib/charts/data";

export const leadsBoard: IChart[] = [
    new Pie('线索池', 6, {
        series: {
            db: 'market',
            sql: 'select count(case when collect_sjd_user_create_at is null and collect_sjd_user_min_id is not null then 1 else null end) as 仅门店,count(case when collect_sjd_user_create_at is not null and collect_sjd_user_min_id is not null then 1 else null end) as 双身份,count(case when collect_sjd_user_create_at is not null and collect_sjd_user_min_id is null then 1 else null end) as 仅用户 from crm.lead_phone_numbers',
        }
    }),
    new Pie('加过比', 6, {
        series: {
            db: 'market',
            sql: 'select count(case when add_at is null then 1 else null end) as 不通过,count(case when add_at is not null then 1 else null end) as 通过 from crm.lead_phone_numbers where last_greet_at is not null',
        }
    }),
    new Sankey('线索跟进状态', 12, {
        series: {
            db: 'market',
            sql: 'select count(case when collect_sjd_user_create_at is null and collect_sjd_user_min_id is not null then 1 else null end) as 仅用户,count(case when collect_sjd_user_create_at is not null and collect_sjd_user_min_id is not null then 1 else null end) as 双身份,count(case when collect_sjd_user_create_at is not null and collect_sjd_user_min_id is null then 1 else null end) as 仅门店,status as 状态 from crm.lead_phone_numbers group by status'
        }
    }, (arr: JsonObject[]): ISankeyDataItem[] => {
        const names: ISankeyDataItem[] = []
        if (arr.length) Object.keys(arr[0]).filter(i => i != '状态').forEach(i => names.push({name: i}))
        arr.forEach(i => names.push({name: i['状态']}))
        return names
    }, (arr: JsonObject[]): ISankeyDataLinkItem[] => {
        const links: ISankeyDataLinkItem[] = []
        arr.forEach(i => {
            Object.keys(i).forEach(k => {
                if (k != '状态' && i[k] > 0) links.push({source: k, target: i['状态'], value: i[k]})
            })
        })
        return links
    }),
    new MixBar('线索跟进进展', 24, [new TabDate(), new TabMonth()], [
        {
            name: '成为好友数',
            type: 'bar',
            series: {
                db: 'market',
                sql: `select date_trunc('_TS_',add_at) ts,count(1) v from crm.lead_phone_numbers where add_at>_ROUND_ group by ts`
            },
        },
        {
            name: '冷启动号码数',
            type: 'bar',
            series: {
                db: 'market',
                sql: `select date_trunc('_TS_',greet_at) ts,count(1) v from crm.lead_phone_number_greets where greet_at>_ROUND_ group by ts`
            },
        },
        {
            name: '业务代表微信参与数',
            type: 'bar',
            series: {
                db: 'market',
                sql: `select date_trunc('_TS_',greet_at) ts,count(distinct greet_wx_open_id) v from crm.lead_phone_number_greets where greet_at>_ROUND_ group by ts`
            },
        },
    ])
]