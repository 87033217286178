import {IChart} from "@/lib/charts/charts";
import {ITableData} from "@/lib/charts/table";
import {EChartsType, init} from "echarts";
import {IBarDate, IDataItem, IDataQuery} from "@/lib/charts/data";
import {ITab} from "@/lib/charts/tab";
import {req} from "@/lib/utils";

export interface IBarModel {
    name: string
    type: 'line' | 'bar'
    series: IDataQuery
    detail?: IDataQuery
    chartData?: IDataItem[]
    detailData?: ITableData
    yAxisIndex?: number
}

interface IBarDataItem {
    readonly at: Date
    readonly v: number
}

export default class StackBar implements IChart {
    readonly span: number;
    readonly title: string;
    protected ct: EChartsType | null = null


    tab: ITab;
    readonly tabs: ITab[];
    protected model: IBarModel[];
    protected showDetail: (title: string, d: ITableData) => void
    protected spin: (spin: boolean) => void


    constructor(title: string, span: number, tabs: ITab[], data: IBarModel[]) {
        this.title = title
        this.tabs = tabs
        this.tab = this.tabs[0]
        this.model = data
        this.span = span
        this.showDetail = (): void => {
            // empty
        }
        this.spin = (): void => {
            // empty
        }
    }

    onSpin(fn: (spin: boolean) => void): void {
        this.spin = fn
    }

    init(dom: HTMLElement): void {
        this.ct = init(dom, 'dark')
        this.ct.on('click', evt => {
            if (!evt.seriesIndex) return
            const d = this.model[evt.seriesIndex].detail
            if (!d) return
            evt.seriesIndex = this.model.length - evt.dataIndex - 1
            req({
                url: 'query',
                method: 'POST',
                data: {
                    db: d.db,
                    sql: this.tab.parseDetailSql(d.sql, evt.dataIndex)
                },
                success: (r: ITableData) => {
                    console.log(r)
                    this.showDetail(this.model[evt.seriesIndex ?? 0].name, r)
                }
            })
        })
        return
    }

    setOption():void {
        if (!this.ct) return
        this.ct.setOption({
            title: {
                text: this.title,
                left: 12,
                top: 12
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: this.tooltip
            },
            legend: {top: 12},
            grid: {left: '56px', right: '56px'},
            xAxis: {
                type: 'category',
                data: this.tab.category()
            },
            yAxis: [{type: 'value'}, {type: 'value'}],
            series: this.series()
        })
    }

    protected tooltip(param: any[]): string | HTMLElement | HTMLElement[] {
        let all = 0
        param.forEach(p => all += p.value)
        let s = param[0].axisValueLabel + ` 共计：${all}<br/>`
        for (const e of param) {
            let v = e.value * 100 / all
            v = isNaN(v) ? 0 : v
            s += e.marker + ` ${e.seriesName}: ${e.value} (${Math.round(v)}%)<br />`
        }
        return s
    }

    protected series(): IBarDate[] {
        const arr = []
        for (const st of this.model) {
            arr.unshift({
                type: st.type,
                name: st.name,
                data: this.tab.dataFormat(st.chartData!),
                showBackground: true,
                stack: 'all',
                yAxisIndex: st.yAxisIndex ?? 0,
            })
        }
        return arr;
    }

    load(): Promise<unknown> {
        return new Promise((resolve, reject) => {
            this.spin(true)
            Promise.all(this.model.map(s => {
                return new Promise((resolve, reject) => {
                    req({
                        url: 'query',
                        method: 'POST',
                        data: {db: s.series.db, sql: this.tab.parseGatherSql(s.series.sql)},
                        success: (r: ITableData) => {
                            s.chartData = r.list.map((i): IBarDataItem => ({at: new Date(i[0]), v: i[1] as number}))
                            resolve(r)
                            this.setOption()
                        },
                        fail: err => reject(err)
                    })
                })
            })).then(resolve).catch(reject).finally(() => this.spin(false))
        })
    }

    onDetailShow(fn: (title: string, data: ITableData) => void): void {
        this.showDetail = fn
    }

    setTab(tab: ITab): void {
        this.tab = tab
    }
}


export class MixBar extends StackBar {

    protected tooltip(param: any[]): string | HTMLElement | HTMLElement[] {
        let all = 0
        param.forEach(p => all += p.value)
        let s = param[0].axisValueLabel + `<br/>`
        for (const e of param) {
            let v = e.value * 100 / all
            v = isNaN(v) ? 0 : v
            s += e.marker + ` ${e.seriesName}: ${e.value}<br />`
        }
        return s
    }

    protected series(): IBarDate[] {
        const arr = []
        for (const st of this.model) {
            arr.unshift({
                type: st.type,
                name: st.name,
                data: this.tab.dataFormat(st.chartData!),
                showBackground: true,
                yAxisIndex: st.yAxisIndex ?? 0,
            })
        }
        return arr;
    }

}