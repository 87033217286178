import {IChart} from "@/lib/charts/charts";
import {ITab, TabEmpty} from "@/lib/charts/tab";
import {ITableData} from "@/lib/charts/table";
import {IDataQuery, JsonObject} from "@/lib/charts/data";
import {EChartsType, init} from "echarts";
import {req} from "@/lib/utils";

export interface ISankeyDataItem {
    name: string
}

export interface ISankeyDataLinkItem {
    source: string
    target: string
    value: number
}

export interface ISankeyModel {
    series: IDataQuery
    chartData?: JsonObject[]
}

export default class Sankey implements IChart {
    readonly span: number;
    tab: ITab;
    readonly tabs: ITab[];
    readonly title: string;

    protected model: ISankeyModel;
    protected showDetail: (title: string, d: ITableData) => void
    protected spin: (spin: boolean) => void
    protected toNames: (arr: JsonObject[]) => ISankeyDataItem[]
    protected toLinks: (arr: JsonObject[]) => ISankeyDataLinkItem[]

    protected ct: EChartsType | null = null

    constructor(title: string, span: number, model: ISankeyModel, toNames: (arr: JsonObject[]) => ISankeyDataItem[], toLinks: (arr: JsonObject[]) => ISankeyDataLinkItem[]) {
        this.title = title
        this.span = span
        this.tabs = [new TabEmpty()]
        this.tab = this.tabs[0]
        this.model = model
        this.toNames = toNames
        this.toLinks = toLinks
        this.showDetail = (): void => {
            // empty
        }
        this.spin = (): void => {
            // empty
        }
    }

    init(dom: HTMLElement): void {
        this.ct = init(dom, 'dark')
        this.ct.on('click', evt => {
            if (!evt.seriesIndex) return
        })
    }

    setOption(): void {
        if (!this.ct) return
        this.ct.setOption({
            title: {
                text: this.title,
                left: 12,
                top: 12
            },
            tooltip: {
                trigger: 'item',
            },
            series: {
                top: '15%',
                left: '20px',
                right: '80px',
                type: 'sankey',
                lineStyle: {
                    color: 'source',
                    curveness: 0.5
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 24,
                        fontWeight: 'bold'
                    }
                },
                data: this.toNames(this.model.chartData ?? []),
                links: this.toLinks(this.model.chartData ?? [])
            }
        })
    }

    load(): Promise<unknown> {
        return new Promise((resolve, reject) => {
            this.spin(true)
            req({
                url: 'query/json',
                method: 'POST',
                data: {db: this.model.series.db, sql: this.tab.parseGatherSql(this.model.series.sql)},
                success: (r: JsonObject[]) => {
                    this.model.chartData = r
                    resolve(r)
                    this.setOption()
                },
                fail: err => reject(err),
                complete: () => this.spin(false)
            })
        })
    }

    onDetailShow(fn: (title: string, data: ITableData) => void): void {
        this.showDetail = fn
    }

    onSpin(fn: (spin: boolean) => void): void {
        this.spin = fn
    }

    setTab(): void {
        // nothing to do
    }

}